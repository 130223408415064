import React, { useEffect, useState } from "react";
import { Select, MenuItem, InputLabel, FormControl, Grid } from "@mui/material";
import BillingListTable from "./table";
import {
  MainWrapper,
  NavigatePage,
  TableLoadingSkeleton,
} from "../../components";
import {
  useLazyGetInvoicesByCompanyQuery,
  useUpdateBillingStatusMutation,
} from "../../app/services/invoices.api";
import { useLocation, useParams } from "react-router-dom";

function BillingListPage() {
  const { companyId } = useParams();
  const { state } = useLocation();
  const [year, setYear] = useState(2024);
  const [fetchInvoicesByCompany, { data, isLoading }] =
    useLazyGetInvoicesByCompanyQuery();
  const [updateBillingStatus] = useUpdateBillingStatusMutation();

  useEffect(() => {
    if (companyId && year) {
      fetchInvoicesByCompany({ companyId, year });
    }
  }, [companyId, year]);

  const onSelectChange = (e) => {
    setYear(e.target.value);
  };

  const onRequest = (data) => {
    const requestBody = {
      companyId: data.id,
      body: {
        year: data.year,
        month: data.month,
      },
    };

    updateBillingStatus(requestBody)
      .unwrap()
      .then(() => {
        fetchInvoicesByCompany({ companyId, year });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MainWrapper>
      <NavigatePage />
      <div>
        <div style={{ textAlign: "center", marginBottom: "50px"}}>
          <h3>{state && state.companyName && state.companyName}</h3>
        </div>
        <Grid container spacing={2} justifyContent="start">
          <Grid item xs={12} sm={3} md={2}>
          年指定
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <FormControl fullWidth>
              <InputLabel id="select-purchase-stores-list-year">年</InputLabel>
              <Select
                labelId="select-purchase-stores-list-year"
                id="select-purchase-stores-list-year"
                name="year"
                value={year}
                label="Year"
                fullWidth
                onChange={onSelectChange}
              >
                <MenuItem value={2023}>2023</MenuItem>
                <MenuItem value={2024}>2024</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <br />
        {isLoading && !data ? (
          <TableLoadingSkeleton />
        ) : (
          <BillingListTable data={data} onRequest={onRequest} />
        )}
      </div>
    </MainWrapper>
  );
}

export default BillingListPage;
