import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import AppraisalView from "../appraisals/View";
import ReportView from "../reports/View";
import { AuthGuard } from "../auth/AuthGuard";
import { AuthUserProvider } from "../auth/AuthContext";
import PasswordChangeView from "../auth/PasswordChange";
import LoginView from "../auth/Login";
import RegisterView from "../auth/Register";
import AdminPortal from "../admin-portal";
import BillingListPage from "../billing-list";
import AssessmentRequestList from "../assessment-request-list";
import CreateNoticePage from "../create-notice";
import PasswordResetView from "../password-reset";

export default function Routing() {
  return (
    <AuthUserProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Navigate to="/console/login" />} />
          <Route exact path="/console/notice/create" element={<CreateNoticePage />} />
          <Route exact path="/console/notice/edit" element={<CreateNoticePage />} />
          <Route exact path="/console/login" element={<LoginView />} />
          <Route exact path="/console/billing/:companyId" element={<BillingListPage />} />
          <Route exact path="/console/assessment-request-list/:shopId/:main/:mainId" element={<AssessmentRequestList />} />
          <Route
            exact
            path="/console/appraisals"
            element={
              <AuthGuard
                component={<AppraisalView />}
                redirect="/console/login"
              />
            }
          />
          <Route
            exact
            path="/console/report"
            element={
              <AuthGuard component={<ReportView />} redirect="/console/login" />
            }
          />
          <Route
            exact
            path="/console/password-change"
            element={
              <AuthGuard component={<PasswordChangeView />} redirect="/console/login" />
            }
          />
          <Route
            exact
            path="/console/register"
            element={
              <AuthGuard component={<RegisterView />} redirect="/console/login" />
            }
          />
          <Route
            exact
            path="/console/dashboard"
            element={
              <AuthGuard component={<AdminPortal />} redirect="/console/login" />
            }
          />
          <Route exact path="/console/reset-password" element={<PasswordResetView />} />
        </Routes>
      </BrowserRouter>
    </AuthUserProvider>
  );
}
