import Button from "../../components/button";
import Text from "../../components/text";
import Edit from "./Edit";
import option from "../../data/choises.json";
import car from "../../data/car.json";
import choise_option from "../../data/choises.json";
import { useState } from "react";
import Shop from "./Shop";
import MatchConfirm from "./MatchConfirm";
import Typography from "@mui/material/Typography";
import {
  convertToJapaneseTime
} from "../../utils/generateTimeList";

export default function Appraisal(props) {
  const [editing, setEditing] = useState(false);
  const [open_choise, setOpenChoise] = useState(false);
  const [open_match, setOpenMatch] = useState(false);
  const status_color_label = (status) =>
    option.status.filter((e) => e.value === status)[0];
  const appraisal_type_color_label = (atype) =>
    option.appraisal_type.filter((e) => e.value === atype)[0];
  const d = car;
  const car_maker = (maker) => car.car.filter((e) => e.value === maker)[0];
  const car_type = (maker, type) =>
    car_maker(maker)?.type?.filter((e) => e.value === type)[0];
  const model_year = (year) => car.year.filter((e) => e.value == year)[0];
  const distance = (distance) =>
    car.distance.filter((e) => e.value == distance)[0];
  const desire = (desire_date) =>
    choise_option.desire_date.filter((e) => e.value === desire_date)[0];
  // const shops = (shopsObj) =>
  //   shopsObj != null ? shopsObj.shops.map((e) => e.name).join("、") : "";

  const firstAssessedDatetime = (shopsObj) => {
    if (shopsObj && shopsObj.shops.length > 0) {
      const originalDate = new Date(shopsObj.shops[0].assessed_datetime);
      const year = originalDate.getFullYear();
      const month = originalDate.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month index
      const day = originalDate.getDate();
      const hours = originalDate.getHours();
      const minutes = originalDate.getMinutes();
      const seconds = originalDate.getSeconds();

      // Pad single digit values with leading zero
      const formattedMonth = month;
      const formattedDay = day;
      const formattedHours = hours;
      const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
      const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;

      return `${year}/${formattedMonth}/${formattedDay} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return "";
    }
  };

  const data = props.data;
  const detail = props.data;

  const shopClose = () => {
    setOpenChoise(!open_choise);
    if (props.refreshData) props.refreshData();
  };
  const editClose = () => {
    setEditing(!editing);
    if (props.refreshData) props.refreshData();
  };
  const matchClose = () => {
    setOpenMatch(!open_match);
    if (props.refreshData) props.refreshData();
  };

  const filterDesireDate = (data) => {
    let desireDate = data;

    if (data === "1ヶ月以内" || data === "1ヵ月以内") {
      desireDate = "1ヵ月以内";
    } else if (data === "2ヶ月以内" || data === "2ヵ月以内") {
      desireDate = "2ヵ月以内";
    } else if (data === "未定") {
      desireDate = "未定";
    }

    return desireDate;
  };

  const filterDistance = (data) => {
    let distanceT = data;
    if (data === "不明") {
      distanceT = "（指定なし）";
    }
    else if (data === "210,000km～") {
      distanceT = "～210,000km";
    }
    return distanceT;
  };

  return (
    <>
      <div className="card" key={data?.appraisalid?.content}>
        <div className="each_column buttons">
          <Button {...status_color_label(data?.status)} on middle></Button>
          <Button
            {...appraisal_type_color_label(data?.type)}
            on
            middle
          ></Button>
          <Text value={`申請日：${data?.supplement?.requestYMD}`} />
          {data?.shops && data?.assessed?.emailSendTime && (
            <Text value={`送客日：${convertToJapaneseTime(data?.assessed)}`} />
          )}
        </div>
        <div className="each_column customer-info-area">
          <Text value={`${data?.customer?.name} 様`} big />
          <div className="flex ">
            <Text value={`${data?.customer?.phone?.content}`} />
            <Text value={`${data?.customer?.email?.content}`} />
          </div>
          <div className="vflex ">
            <Text value={`〒 ${data?.customer?.post_number}`} />
            <Text
              value={`${(choise_option.prefecture &&
                  choise_option.prefecture.filter(
                    (e) => data?.customer?.prefecture === e.value
                  )[0]?.label) ||
                ""
                }${data?.customer?.municipalities || ""}${data?.customer?.address || ""
                }${data?.customer?.address_detail || ""}`}
            />
          </div>
        </div>
        <div className="each_column car-info-area">
          <Text
            value={data?.car?.car_type || ""}
            big
          />
          <div className="flex ">
            <Text value={`${car_maker(data?.car?.car_maker)?.label}`} />
            <Text value={data?.car?.car_model_year || ""} />
            <Text
              value={`${distance(filterDistance(data?.car?.car_traveled_distance))
                  ?.label
                }`}
            />
          </div>
          <div className="flex ">
            <Text
              value={`売却希望：${data?.car?.desire_date
                  ? desire(filterDesireDate(data?.car?.desire_date))?.label
                  : ""
                }`}
            />
            <div className="flex ">
              <Text value={`振分先: `} />
              {data?.shops &&
                data?.shops?.shops.map((shop, index) => (
                  <span
                    key={index}
                    className={`typography-s ${shop.is_rejected_by_shop && "text-error"
                      }`}
                    style={{ marginRight: 5 }}
                  >
                    {shop.name}
                    {index < data?.shops?.shops.length - 1 && <span>, </span>}
                  </span>
                ))}
            </div>
          </div>
          <div className="flex ">
            <Text value={`備考：${data?.supplement?.note || ""}`} />
            <Text value={`パラメータ：${data?.supplement?.param || ""}`} />
          </div>
        </div>
        {data?.test_email_sent && (
          <div className="each_column">
            <Text value="" />
            <Text value="" />
            <Typography sx={{ fontSize: "smaller" }} color="error">
              テストメール送信済
            </Typography>
          </div>
        )}
        <div className="each_column buttons">
          <Button
            label="買取店選択"
            color={"sub"}
            on
            onClick={shopClose}
          ></Button>
          <Button label="編集" color={"sub"} on onClick={editClose}></Button>
          <Button label="送客" color={"main"} on onClick={matchClose}></Button>
        </div>
      </div>
      {editing && <Edit data={detail} onClose={editClose} />}
      {open_choise && (
        <Shop
          data={detail}
          onClose={shopClose}
          appraisalid={data?.appraisalid?.content}
        />
      )}
      {open_match && <MatchConfirm data={detail} onClose={matchClose} />}
    </>
  );
}
