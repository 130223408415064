import React, { useEffect, useState } from "react";
import { Checkbox, Button } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import TypedInputNumber from "antd/es/input-number";

export const Inputcard = (props) => {
  const { results, setResult } = props;
  const [components, setComponents] = useState([
    { id: null, week: "", days: [] },
  ]);
  const [initialValuesLoaded, setInitialValuesLoaded] = useState(false);
  const [error, setError] = useState("");

  const jpWeekDays = [
    { label: "月", value: "MONDAY" },
    { label: "火", value: "TUESDAY" },
    { label: "水", value: "WEDNESDAY" },
    { label: "木", value: "THURSDAY" },
    { label: "金", value: "FRIDAY" },
    { label: "土", value: "SATURDAY" },
    { label: "日", value: "SUNDAY" },
  ];

  useEffect(() => {
    if (!initialValuesLoaded && results?.length > 0) {
      const groupedComponents = results?.reduce((acc, item) => {
        const foundIndex = acc?.findIndex((comp) => comp?.week === item?.week);
        if (foundIndex !== -1) {
          acc[foundIndex].days.push(item?.day);
        } else {
          acc.push({ id: item.id, week: item?.week, days: [item?.day] });
        }
        return acc;
      }, []);

      setComponents(groupedComponents);
      setInitialValuesLoaded(true);
    }
  }, [results, initialValuesLoaded]);

  const addComponent = () => {
    if (
      components?.length < 6 &&
      components[components?.length - 1].week !== "" &&
      components[components?.length - 1].days?.length > 0
    ) {
      setComponents([...components, { id: null, week: "", days: [] }]);
    }
  };

  const removeComponent = (index) => {
    if (index > 0) {
      const newComponents = [...components];
      newComponents?.splice(index, 1);
      setComponents(newComponents);
    }
  };

  const handleWeekChange = (index, value) => {
    const newComponents = [...components];
    if (value === null || value === undefined) {
      newComponents[index].week = null;
      newComponents[index].days = [];
      setError("");
    } else if (value < 0 || value > 5) {
      setError("定休日の間隔に1から5までの数字を入力してください");
    } else {
      newComponents[index].week = value;
      setError("");
    }
    setComponents(newComponents);
  };

  const handleCheckboxChange = (index, value) => {
    const newComponents = [...components];
    const days = newComponents[index].days;

    if (days?.includes(value)) {
      newComponents[index].days = days?.filter((day) => day !== value);
    } else {
      newComponents[index].days?.push(value);
    }

    setComponents(newComponents);
  };

  const handleSubmit = () => {
    const result = [];
    components?.forEach((component) => {
      component?.days?.forEach((day) => {
        result.push({ id: component?.id, week: Number(component?.week), day });
      });
    });
    setResult(result);
  };

  useEffect(() => {
    handleSubmit();
  }, [components]);

  const canAddComponent =
    components?.length < 6 &&
    components[components?.length - 1].week !== "" &&
    components[components?.length - 1].days?.length > 0;

  return (
    <div className="flex items-start flex-wrap gap-2 ">
      {components?.map((component, index) => (
        <React.Fragment key={index}>
          <div className="w-7/12 flex flex-col items-start gap-4 p-4 border border-[#587c94]">
            <div className="w-full flex items-start justify-between gap-4">
              <div className="flex items-center gap-4">
                <h1 className="text-black">間隔</h1>
                <div className="flex items-center gap-2">
                  <TypedInputNumber
                    min={0}
                    max={5}
                    className="bg-white w-20"
                    onChange={(value) => handleWeekChange(index, value)}
                    value={component?.week}
                  />
                  <h1 className="text-black">週ごと</h1>
                </div>
              </div>
            </div>
            {error && (
              <div className="text-red-500 w-full text-left">{error}</div>
            )}
            <div className="w-full flex flex-wrap gap-4">
              {jpWeekDays?.map((day) => (
                <div key={day?.value} className="flex items-center gap-2">
                  <Checkbox
                    value={day?.value}
                    checked={component?.days?.includes(day?.value)}
                    onChange={() => handleCheckboxChange(index, day.value)}
                    disabled={
                      component?.week === undefined || component?.week === null
                    }
                  >
                    {day?.label}
                  </Checkbox>
                </div>
              ))}
            </div>
          </div>
          <div className="my-1">
            {index > 0 && (
              <Button
                className="rounded-full"
                onClick={() => removeComponent(index)}
                icon={<DeleteOutlined />}
                danger
              />
            )}
          </div>
        </React.Fragment>
      ))}
      <Button
        className="rounded-full mx-4"
        onClick={addComponent}
        icon={<PlusOutlined />}
        disabled={!canAddComponent}
      />
    </div>
  );
};
