import { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "../../components/button";
import Text from "../../components/text";
import Appraisal from "./Appraisal";
import Input from "../../components/input";
import { useBackend } from "../../hooks/useBackend";
import AppraisalList from "./AppraisalList";
import DateTime from "../../components/datetime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import * as React from "react";

export default function MainView(props) {
  const [clicked, setClicked] = useState({ on: false, off: true });
  const [editing, setEditing] = useState(false);
  const [appraisal_data, setAppraisalData] = useState(null);
  const [appraisal_data_count, setAppraisalDataCount] = useState(0);
  const [not_compatible, set_not_compatible] = useState(false);
  const [processing, set_processing] = useState(false);
  const [appointment, set_appointment] = useState(false);
  const [closed, set_closed] = useState(false);
  const [unsent, set_unsent] = useState(false);
  const [cancelled, set_cancelled] = useState(false);
  const [submit_an_application, set_submit_an_application] = useState(false);
  const [recognition_applied, set_recognition_applied] = useState(false);
  const [recognition_not_accepted, set_recognition_not_accepted] =
    useState(false);
  const [seo, set_seo] = useState(false);
  const [paid, set_paid] = useState(false);
  const [paid2, set_paid2] = useState(false);
  const [paid3, set_paid3] = useState(false);
  const [paid4, set_paid4] = useState(false);
  const [phone, set_phone] = useState(false);
  const [testData, setTestData] = useState(false);
  const [phone_no, set_phone_no] = useState("");
  const [kw, set_kw] = useState("");
  const fromInputRef = useRef(null);
  const toInputRef = useRef(null);
  const [fromValue, setFromValue] = useState();
  const [toValue, setToValue] = useState();
  //pagination
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(20);

  //----------
  const edit = () => {
    setEditing(!editing);
  };
  //create update page for when click filter
  const update_page = () => {
    const offsetTB = (1 - 1) * limit;
    setPage(1);
    setOffset(offsetTB);
  };
  const toggle_not_compatible = () => {
    update_page();
    set_not_compatible(!not_compatible);
  };
  const toggle_processing = () => {
    update_page();
    set_processing(!processing);
  };
  const toggle_appointment = () => {
    update_page();
    set_appointment(!appointment);
  };
  const toggle_closed = () => {
    update_page();
    set_closed(!closed);
  };
  const toggle_unsent = () => {
    update_page();
    set_unsent(!unsent);
  };

  const toggle_submit_an_application = () => {
    update_page();
    set_submit_an_application(!submit_an_application);
  };
  const toggle_recognition_applied = () => {
    update_page();
    set_recognition_applied(!recognition_applied);
  };
  const toggle_recognition_not_accepted = () => {
    update_page();
    set_recognition_not_accepted(!recognition_not_accepted);
  };
  const toggle_seo = () => {
    update_page();
    set_seo(!seo);
  };
  const toggle_paid = () => {
    update_page();
    set_paid(!paid);
  };
  const toggle_paid2 = () => {
    update_page();
    set_paid2(!paid2);
  };
  const toggle_paid3 = () => {
    update_page();
    set_paid3(!paid3);
  };
  const toggle_paid4 = () => {
    update_page();
    set_paid4(!paid4);
  };
  const toggle_phone = () => {
    update_page();
    set_phone(!phone);
  };
  const toggle_testData = () => {
    update_page();
    setTestData(!testData);
  };
  const input_phone_no = (d) => {
    update_page();
    set_phone_no(d.phone);
  };
  const input_kw = (d) => {
    update_page();
    set_kw(d.kw);
  };

  const toggle_cancel = () => {
    update_page();
    set_cancelled(!cancelled);
  };
  const api = useBackend();

  useEffect(() => {
    api.appraisal_list().then((e) => {
      setAppraisalData(e.data?.operatorAppraisalList);
    });
  }, [props.editing]);

  //pagination
  useEffect(() => {
    setCount(Math.ceil(appraisal_data_count / limit));
  }, [appraisal_data_count]);

  const dateTimeValueFromRef = (r) =>
    Array.from(r.current.children[0]?.children).filter(
      (e) => e.nodeName === "INPUT"
    )[0]?.value;
  const correctDate = (t) =>
    !(t === "" || t.match(/Y/) || t.match(/M/) || t.match(/D/));

  //pagination
  const handleChange = (event, value) => {
    const offsetTB = (value - 1) * limit;
    setPage(value);
    setOffset(offsetTB);
  };
  //----------
  const apply = () => {
    const d = {
      not_compatible: not_compatible,
      processing: processing,
      appointment: appointment,
      closed: closed,
      unsent: unsent,
      submit_an_application: submit_an_application,
      recognition_applied: recognition_applied,
      recognition_not_accepted: recognition_not_accepted,
      cancelled: cancelled,
      seo: seo,
      paid: paid,
      paid2: paid2,
      paid3: paid3,
      paid4: paid4,
      phone: phone,
      phone_no: phone_no,
      isTestData: testData,
      kw: kw,
      offset: offset,
      limit: limit,
    };
    let from = dateTimeValueFromRef(fromInputRef);
    if (correctDate(from)) {
      setFromValue(from);
      d.from = from;
    }

    let to = dateTimeValueFromRef(toInputRef);
    if (correctDate(to)) {
      setToValue(dateTimeValueFromRef(toInputRef));
      d.to = dateTimeValueFromRef(toInputRef);
    }
    api.appraisal_list(d).then((e) => {
      setAppraisalData(e.data?.operatorAppraisalList);
      setAppraisalDataCount(e.data?.allCount);
    });
  };

  useEffect(apply, [
    not_compatible,
    processing,
    appointment,
    closed,
    unsent,
    submit_an_application,
    recognition_applied,
    recognition_not_accepted,
    cancelled,
    seo,
    paid,
    paid2,
    paid3,
    paid4,
    phone,
    kw,
    testData,
    page,
  ]);
  const refresh_appraisal_data = () => {
    setTimeout(apply, 100);
  };

  return (
    <div>
      <Box
        maxWidth={"100vw"}
        sx={{
          width: "100%",
          padding: "30px",
          alignContent: "center",
          marginBottom: "10px",
        }}
      >
        <Stack
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing={1} sx={{}}>
            <Button
              small
              label="未対応"
              color={"red"}
              has_state
              {...clicked}
              onClick={toggle_not_compatible}
            />
            <Button
              small
              label="対応中"
              color={"yellow"}
              has_state
              {...clicked}
              onClick={toggle_processing}
            />
            <Button
              small
              label="アポ確定"
              color={"blue"}
              has_state
              {...clicked}
              onClick={toggle_appointment}
            />
            <Button
              small
              label="送客済"
              color={"gray"}
              has_state
              {...clicked}
              onClick={toggle_closed}
            />
            <Button
              small
              label="未送客"
              color={"purple"}
              has_state
              {...clicked}
              onClick={toggle_unsent}
            />
            <Button
              label="キャンセル済み"
              color={"green"}
              has_state
              {...clicked}
              onClick={toggle_cancel}
            />
          </Stack>
          <Stack direction={{ sm: "column", md: "row" }} spacing={1} sx={{}}>
            <Button
              xs
              label="SEO"
              color={"main"}
              has_state
              {...clicked}
              onClick={toggle_seo}
            />
            <Button
              xs
              label="Paid"
              color={"main"}
              has_state
              {...clicked}
              onClick={toggle_paid}
            />
            <Button
              small2
              label="Paid2"
              color={"main"}
              has_state
              {...clicked}
              onClick={toggle_paid2}
            />
            <Button
              small2
              label="Paid3"
              color={"main"}
              has_state
              {...clicked}
              onClick={toggle_paid3}
            />
            <Button
              small2
              label="Paid4"
              color={"main"}
              has_state
              {...clicked}
              onClick={toggle_paid4}
            />
            <Button
              xs
              label="架電"
              color={"main"}
              has_state
              {...clicked}
              onClick={toggle_phone}
            />
          </Stack>
          <Stack
            direction={{ md: "column", xl: "row" }}
            spacing={2}
            alignItems="center"
          >
            <Input
              label="電話番号"
              id="phone"
              narrow
              onChange={input_phone_no}
            />
            <Input label="キーワード" id="kw" narrow onChange={input_kw} />
          </Stack>
          <Stack
            direction={{ md: "column", xl: "row" }}
            spacing={2}
            alignItems="center"
          >
            <DateTime
              _ref={fromInputRef}
              label="From"
              name="from"
              id="from"
              narrow
              noTime
              selected={fromValue}
            />
            <DateTime
              _ref={toInputRef}
              label="To"
              name="to"
              id="to"
              narrow
              noTime
              selected={toValue}
            />
          </Stack>
          <Stack
            direction={{ md: "column", xl: "row" }}
            spacing={2}
            alignItems="center"
          >
            <Button xs on label="適用" color={"main"} onClick={apply} />
            <Button
              small
              on
              label="クリア"
              color={"gray"}
              onClick={() => window.location.reload()}
            />
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          paddingRight="60px"
          marginTop="5px"
        >
          <Stack
            direction={{ xl: "row" }}
            spacing={{ xl: 1 }}
            alignItems="center"
            style={{ marginLeft: "60px" }}
          >
            <Button
              label="テストデータ一覧"
              color={"main"}
              has_state
              {...clicked}
              onClick={toggle_testData}
            />
            <Typography variant="body1" fontWeight="bold" fontSize={20}>
              件数：{appraisal_data_count || "0"}
            </Typography>
          </Stack>
        </Stack>
        <Box
        maxWidth={"100vw"}
        sx={{
          width: "100%",
          marginTop: {
            xs: "-5%", 
            sm: "-5%", 
            md: "-12%", 
            lg: "-8%", 
            xl: "-1%", 
          },
          paddingTop: "20px",
          alignContent: "center",
        }}
      >
        <Stack
          direction="row"
          spacing={3}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Stack direction={{ sm: "column", md: "row" }} spacing={1} sx={{}}>
            {/* <Button
              middle
              label="却下申請棄却"
              color={"green"}
              has_state
              {...clicked}
              onClick={toggle_submit_an_application}
            />
            <Button
              middle
              label="却下申請中"
              color={"green"}
              has_state
              {...clicked}
              onClick={toggle_recognition_applied}
            />
            <Button
              middle
              label="却下申請承認"
              color={"green"}
              has_state
              {...clicked}
              onClick={toggle_recognition_not_accepted}
            /> */}
          </Stack>
        </Stack>
      </Box>
      </Box>
      

      <Box sx={{ display: "flex", flexDirection: "column", height: "80vh" }}>
        <AppraisalList
          appraisal_data={appraisal_data}
          refreshData={refresh_appraisal_data}
          count={count}
          page={page}
          handleChange={handleChange}
        />
      </Box>
    </div>
  );
}
