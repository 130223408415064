export const NewPrefectureList = [
  { key: 0, label: "全国", value: "全国" },
  {
    key: 1,
    label: "北海道",
    value: "北海道",
  },
  {
    key: 2,
    label: "青森県",
    value: "青森県",
  },
  {
    key: 3,
    label: "岩手県",
    value: "岩手県",
  },
  {
    key: 4,
    label: "宮城県",
    value: "宮城県",
  },
  {
    key: 5,
    label: "秋田県",
    value: "秋田県",
  },
  {
    key: 6,
    label: "山形県",
    value: "山形県",
  },
  {
    key: 7,
    label: "福島県",
    value: "福島県",
  },
  {
    key: 8,
    label: "茨城県",
    value: "茨城県",
  },
  {
    key: 9,
    label: "栃木県",
    value: "栃木県",
  },
  {
    key: 10,
    label: "群馬県",
    value: "群馬県",
  },
  {
    key: 11,
    label: "埼玉県",
    value: "埼玉県",
  },
  {
    key: 12,
    label: "千葉県",
    value: "千葉県",
  },
  {
    key: 13,
    label: "東京都",
    value: "東京都",
  },
  {
    key: 14,
    label: "神奈川県",
    value: "神奈川県",
  },
  {
    key: 15,
    label: "新潟県",
    value: "新潟県",
  },
  {
    key: 16,
    label: "富山県",
    value: "富山県",
  },
  {
    key: 17,
    label: "石川県",
    value: "石川県",
  },
  {
    key: 18,
    label: "福井県",
    value: "福井県",
  },
  {
    key: 19,
    label: "山梨県",
    value: "山梨県",
  },
  {
    key: 20,
    label: "長野県",
    value: "長野県",
  },
  {
    key: 21,
    label: "岐阜県",
    value: "岐阜県",
  },
  {
    key: 22,
    label: "静岡県",
    value: "静岡県",
  },
  {
    key: 23,
    label: "愛知県",
    value: "愛知県",
  },
  {
    key: 24,
    label: "三重県",
    value: "三重県",
  },
  {
    key: 25,
    label: "滋賀県",
    value: "滋賀県",
  },
  {
    key: 26,
    label: "京都府",
    value: "京都府",
  },
  {
    key: 27,
    label: "大阪府",
    value: "大阪府",
  },
  {
    key: 28,
    label: "兵庫県",
    value: "兵庫県",
  },
  {
    key: 29,
    label: "奈良県",
    value: "奈良県",
  },
  {
    key: 30,
    label: "和歌山県",
    value: "和歌山県",
  },
  {
    key: 31,
    label: "鳥取県",
    value: "鳥取県",
  },
  {
    key: 32,
    label: "島根県",
    value: "島根県",
  },
  {
    key: 33,
    label: "岡山県",
    value: "岡山県",
  },
  {
    key: 34,
    label: "広島県",
    value: "広島県",
  },
  {
    key: 35,
    label: "山口県",
    value: "山口県",
  },
  {
    key: 36,
    label: "徳島県",
    value: "徳島県",
  },
  {
    key: 37,
    label: "香川県",
    value: "香川県",
  },
  {
    key: 38,
    label: "愛媛県",
    value: "愛媛県",
  },
  {
    key: 39,
    label: "高知県",
    value: "高知県",
  },
  {
    key: 40,
    label: "福岡県",
    value: "福岡県",
  },
  {
    key: 41,
    label: "佐賀県",
    value: "佐賀県",
  },
  {
    key: 42,
    label: "長崎県",
    value: "長崎県",
  },
  {
    key: 43,
    label: "熊本県",
    value: "熊本県",
  },
  {
    key: 44,
    label: "大分県",
    value: "大分県",
  },
  {
    key: 45,
    label: "宮崎県",
    value: "宮崎県",
  },
  {
    key: 46,
    label: "鹿児島県",
    value: "鹿児島県",
  },
  {
    key: 47,
    label: "沖縄県",
    value: "沖縄県",
  },
];
