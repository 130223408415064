import { Button } from "@mui/material";
import React, { useState } from "react";
import moment from "moment";

const AppraisalCard = ({ appraisal, onUpdateStatus }) => {
  const {
    companyName,
    shopName,
    assessed: {
      id: { appraisalid, shopid },
      status,
      evidenceImage,
      reasonForRejection,
      applicationDate,
      approvalDate,
      dismissalDate,
    },
  } = appraisal;

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [actionType, setActionType] = useState("");

  const handleConfirmation = (type) => {
    setActionType(type);
    setIsOpenDelete(true);
  };

  const handleActionConfirm = () => {
    if (actionType === "approval") {
      onUpdateStatus(appraisalid, shopid, "recognition_applied");
    } else if (actionType === "rejection") {
      onUpdateStatus(appraisalid, shopid, "recognition_not_accepted");
    }
    setIsOpenDelete(false);
  };

  const handleActionCancel = () => {
    setIsOpenDelete(false);
  };

  return (
    <div className="border border-gray-300 p-4 mb-4 rounded-lg ">
      <div className="flex justify-between">
        <div className="justify-start">
          <p className="text-lg text-gray-600 font-semibold mb-2">
            買取店名 : {shopName}
          </p>
        </div>
        <div className="justify-end">
          <p className="text-gray-600 font-semibold mb-2">
            承認ステータス:
            {status === "recognition_applied"
              ? " 承認済み"
              : status === "recognition_not_accepted"
              ? " 棄却済み"
              : status === "submit_an_application"
              ? " 申請中"
              : " --"}
          </p>
        </div>
      </div>

      <div className="flex justify-between mb-4 flex-wrap sm:flex-nowrap">
        <div className="w-full sm:w-1/2 border p-4 mr-0 sm:mr-2 mb-4 sm:mb-0">
          <div className="h-99% md:h-[90%] lg:h-80 xl:h-80  bg-gray-100 flex items-center justify-center">
            {evidenceImage ? (
              <img src={evidenceImage} alt="Evidence" className="max-h-full" />
            ) : (
              <p>エビデンス画像</p>
            )}
          </div>
        </div>

        <div className="w-full sm:w-1/2 border p-4 ml-0 sm:ml-2">
          <div className="h-99% md:h-[90%] lg:h-80 xl:h-80 bg-gray-100 flex items-center justify-center">
            {reasonForRejection ? (
              <p className="px-4">{reasonForRejection}</p>
            ) : (
              <p>却下理由</p>
            )}
          </div>
        </div>
      </div>

      <div className="lg:flex xl:flex md:flex justify-between flex-wrap sm:flex-nowrap ">
        <div className="text-sm text-gray-700 justify-start sm:justify-center">
          <p>
            申請日:{" "}
            {applicationDate
              ? moment(applicationDate).format("YYYY-MM-DD")
              : "--"}
          </p>
          <p>
            承認日:{" "}
            {approvalDate ? moment(approvalDate).format("YYYY-MM-DD") : "--"}
          </p>
          <p>
            棄却日:{" "}
            {dismissalDate ? moment(dismissalDate).format("YYYY-MM-DD") : "--"}
          </p>
        </div>
        <div className="justify-end sm:justify-center">
          <div className="mt-4 flex flex-wrap gap-4">
            <button
              className="bg-[#8E8E93] text-white font-semibold px-4 py-2 rounded-md"
              onClick={() =>
                onUpdateStatus(appraisalid, shopid, "assessment_confirmation")
              }
            >
              査定確認
            </button>
            <button
              className="bg-[#f25f2a] text-white font-semibold px-4 py-2 rounded-md"
              onClick={() => handleConfirmation("rejection")}
            >
              棄却
            </button>
            <button
              className="notice-edit text-white font-semibold px-4 py-2 rounded-md"
              onClick={() => handleConfirmation("approval")}
            >
              承認
            </button>
          </div>
        </div>
      </div>

      {isOpenDelete && (
        <div className="w-screen h-screen fixed bg-transparent top-0 left-0 z-[80]">
          <div className="w-full h-full flex items-center justify-center">
            <div className="bg-white flex flex-col items-center justify-center gap-4 w-4/12 h-[20vh] p-4 shadow-sm shadow-[#f25f2a] border-[#f25f2a] border">
              <h1>
                {actionType === "approval"
                  ? "この却下申請を承認してもよろしいですか?"
                  : "この却下申請を棄却してもよろしいですか?"}
              </h1>
              <div className="flex items-center gap-4">
                <Button variant="contained" onClick={handleActionConfirm}>
                  はい
                </Button>
                <Button variant="contained" onClick={handleActionCancel}>
                  いいえ
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppraisalCard;
