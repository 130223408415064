import React, { useEffect, useMemo, useState } from "react";
import {
  Stack,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import ButtonComponent from "../../components/button";
import { generateCurrentYearMonth } from "../../utils/generate-current-year-month";
import { useBackend } from "../../hooks/useBackend";
import { useAppStore } from "../../stores/app.store";
import moment from "moment";

function PurchaseStoreListSearchSection(props) {
  const api = useBackend();
  const currentMonthYear = generateCurrentYearMonth();
  const [downloading, setDownloading] = useState(false);
  const [name, setName] = useState("");
  const { setFormTabIndex, setEditRegisterFormStatus, setEditRegisterId } =
    useAppStore();
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const [dateType, setDateType] = useState("monthly");

  const currentYear = moment().year();
  const yearRange = useMemo(() => {
    return Array.from({ length: 6 }, (_, i) => currentYear - 5 + i);
  }, [currentYear]);

  const getDaysInMonth = (year, month) => {
    return moment(`${year}-${month}`, "YYYY-M").daysInMonth();
  };

  const fromDaysInMonth = useMemo(() => {
    return getDaysInMonth(
      props.filterParams.fromYear,
      props.filterParams.fromMonth
    );
  }, [props.filterParams.fromYear, props.filterParams.fromMonth]);

  const toDaysInMonth = useMemo(() => {
    return getDaysInMonth(
      props.filterParams.toYear,
      props.filterParams.toMonth
    );
  }, [props.filterParams.toYear, props.filterParams.toMonth]);

  useEffect(() => {
    validateDateRange();
  }, [props.filterParams]);

  const validateDateRange = () => {
    const { fromYear, fromMonth, fromDay, toYear, toMonth, toDay } =
      props.filterParams;
    const fromDate = moment(`${fromYear}-${fromMonth}-${fromDay}`, "YYYY-M-D");
    const toDate = moment(`${toYear}-${toMonth}-${toDay}`, "YYYY-M-D");

    if (toDate.isBefore(fromDate)) {
      props.onChangeFilter("toYear", fromYear);
      props.onChangeFilter("toMonth", fromMonth);
      props.onChangeFilter("toDay", fromDay);
    }

    const actualFromDays = moment(
      `${fromYear}-${fromMonth}`,
      "YYYY-M"
    ).daysInMonth();
    const actualToDays = moment(`${toYear}-${toMonth}`, "YYYY-M").daysInMonth();

    if (parseInt(fromDay) > actualFromDays) {
      props.onChangeFilter("fromDay", actualFromDays.toString());
    }
    if (parseInt(toDay) > actualToDays) {
      props.onChangeFilter("toDay", actualToDays.toString());
    }
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    if (name === "name") {
      setName(value);
      props.onChangeFilter(name, value);
    } else if (name === "dateType") {
      props.onChangeFilter(name, value);
    } else {
      props.onChangeFilter(name, value);
    }

    if (
      [
        "fromYear",
        "fromMonth",
        "fromDay",
        "toYear",
        "toMonth",
        "toDay",
      ].includes(name)
    ) {
      setTimeout(validateDateRange, 0);
    }
  };

  const isToDateDisabled = (year, month, day) => {
    const fromDate = moment(
      `${props.filterParams.fromYear}-${props.filterParams.fromMonth}-${props.filterParams.fromDay}`,
      "YYYY-M-D"
    );
    const toDate = moment(`${year}-${month}-${day}`, "YYYY-M-D");
    return toDate.isBefore(fromDate);
  };

  const clearSearch = () => {
    setName("");
    props.onChangeFilter("name", "");
    props.onSearchFilter();
  };

  const generate_billing_list_report = () => {
    setDownloading(true);
    const totalElement = props.data.totalElement || 0;
    let requestParams;

    if (props.filterParams.dateType === "monthly") {
      requestParams = {
        totalElement,
        year: props.filterParams.year,
        month: props.filterParams.month,
        dateType: "monthly",
      };
    } else {
      requestParams = {
        totalElement,
        year: "0",
        month: "0",
        dateType: "daily",
        fromYear: props.filterParams.fromYear,
        toYear: props.filterParams.toYear,
        fromMonth: props.filterParams.fromMonth,
        toMonth: props.filterParams.toMonth,
        fromDay: props.filterParams.fromDay,
        toDay: props.filterParams.toDay,
      };
    }
    api
      .gen_billing_csv(
        requestParams.totalElement,
        requestParams.year,
        requestParams.month,
        requestParams.dateType,
        requestParams.fromYear,
        requestParams.toYear,
        requestParams.fromMonth,
        requestParams.toMonth,
        requestParams.fromDay,
        requestParams.toDay
      )
      .then((e) => {
        setTimeout(() => {
          setDownloading(false);
          // Create a hidden link to download the file
          const link = document.createElement("a");
          link.href =
            process.env.REACT_APP_APPRAISAL_DATA_BUCKET + "billing.csv";
          link.download = "billing.csv";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }, 1000);
      });
  };

  const onKeyDown = (e) => {
    if (e.keyCode == 13) {
      props.onSearchFilter();
    }
  };

  return (
    <>
      <Grid container spacing={2} justifyContent="start">
        <Grid item xs={12} sm={12} md={6} justifySelf="flex-start">
          <div className="search-wrapper">
            <Stack direction={"row"} spacing={2}>
              <TextField
                fullWidth
                name="name"
                id="outlined-basic"
                label="店名"
                variant="outlined"
                size="small"
                placeholder="店名"
                color="warning"
                value={name}
                onChange={onChange}
                onKeyDown={onKeyDown}
              />
              <ButtonComponent
                label="検索"
                color={"main"}
                on
                onClick={props.onSearchFilter}
              />
              <ButtonComponent
                label="リセット"
                color={"sub"}
                on
                onClick={clearSearch}
              />
            </Stack>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} justifyContent="flex-end">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-end"
            alignItems="center"
          >
            <ButtonComponent
              label="CSV出力"
              color={"sub"}
              on
              onClick={generate_billing_list_report}
              disabled={downloading}
            />
            <ButtonComponent
              label="引落"
              color={"main"}
              off
              has_state
              onClick={() => {
                props.onClickFilter("DEBIT");
              }}
            />
            <ButtonComponent
              label="請求"
              color={"main"}
              off
              has_state
              onClick={() => {
                props.onClickFilter("BILLING");
              }}
            />
          </Stack>
        </Grid>

        <div className=" w-full  flex items-center justify-end">
          <ButtonComponent
            label="新規登録"
            color={"main"}
            off
            has_state
            onClick={() => {
              setFormTabIndex(true);
              setEditRegisterFormStatus(false);
              setEditRegisterId(null);
            }}
          />
        </div>
      </Grid>
      <br />
      {/* <Grid container spacing={2} alignItems="center" marginTop="4px">
      <Grid item xs={12} sm={2} md={1.5}>
        <Typography>年月日指定</Typography>
      </Grid> */}
      <Grid container ml={0} spacing={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item mr="2vw" width="150px">
            <RadioGroup
              name="dateType"
              value={props.filterParams.dateType}
              onChange={onChange}
            >
              <FormControlLabel
                value="monthly"
                control={<Radio />}
                label="年月指定"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="select-year">年</InputLabel>
              <Select
                labelId="select-year"
                id="select-year"
                name="year"
                label="Year"
                value={props.filterParams.year}
                onChange={onChange}
                fullWidth
                disabled={props.filterParams.dateType !== "monthly"}
              >
                {yearRange.map((year) => (
                  <MenuItem key={year} value={year.toString()}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="select-month">月</InputLabel>
              <Select
                labelId="select-month"
                id="select-month"
                name="month"
                label="Month"
                value={props.filterParams.month}
                onChange={onChange}
                fullWidth
                disabled={props.filterParams.dateType !== "monthly"}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <MenuItem key={i + 1} value={(i + 1).toString()}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container mt={4} spacing={2} alignItems="center">
          <Grid item mr="2vw" width="150px">
            <RadioGroup
              name="dateType"
              value={props.filterParams.dateType}
              onChange={onChange}
            >
              <FormControlLabel
                value="daily"
                control={<Radio />}
                label="年月日指定"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12} sm={12} md={1.5}>
            <FormControl fullWidth>
              <InputLabel>年</InputLabel>
              <Select
                label="年"
                name="fromYear"
                value={props.filterParams.fromYear}
                onChange={onChange}
                disabled={props.filterParams.dateType !== "daily"}
              >
                {yearRange.map((year) => (
                  <MenuItem key={year} value={year.toString()}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <FormControl fullWidth>
              <InputLabel>月</InputLabel>
              <Select
                label="月"
                name="fromMonth"
                value={props.filterParams.fromMonth}
                onChange={onChange}
                disabled={props.filterParams.dateType !== "daily"}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <MenuItem key={i + 1} value={(i + 1).toString()}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <FormControl fullWidth>
              <InputLabel>日</InputLabel>
              <Select
                label="日"
                name="fromDay"
                value={props.filterParams.fromDay}
                onChange={onChange}
                disabled={props.filterParams.dateType !== "daily"}
              >
                {Array.from({ length: fromDaysInMonth }, (_, i) => (
                  <MenuItem key={i + 1} value={(i + 1).toString()}>
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={0.5} sx={{ textAlign: "center" }}>
            <Typography>〜</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={1.5}>
            <FormControl fullWidth>
              <InputLabel>年</InputLabel>
              <Select
                label="年"
                name="toYear"
                value={props.filterParams.toYear}
                onChange={onChange}
                disabled={props.filterParams.dateType !== "daily"}
              >
                {yearRange.map((year) => (
                  <MenuItem
                    key={year}
                    value={year.toString()}
                    disabled={year < parseInt(props.filterParams.fromYear)}
                  >
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <FormControl fullWidth>
              <InputLabel>月</InputLabel>
              <Select
                label="月"
                name="toMonth"
                value={props.filterParams.toMonth}
                onChange={onChange}
                disabled={props.filterParams.dateType !== "daily"}
              >
                {Array.from({ length: 12 }, (_, i) => (
                  <MenuItem
                    key={i + 1}
                    value={(i + 1).toString()}
                    disabled={isToDateDisabled(
                      props.filterParams.toYear,
                      i + 1,
                      1
                    )}
                  >
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={1.5}>
            <FormControl fullWidth>
              <InputLabel>日</InputLabel>
              <Select
                label="日"
                name="toDay"
                value={props.filterParams.toDay}
                onChange={onChange}
                disabled={props.filterParams.dateType !== "daily"}
              >
                {Array.from({ length: toDaysInMonth }, (_, i) => (
                  <MenuItem
                    key={i + 1}
                    value={(i + 1).toString()}
                    disabled={isToDateDisabled(
                      props.filterParams.toYear,
                      props.filterParams.toMonth,
                      i + 1
                    )}
                  >
                    {i + 1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default PurchaseStoreListSearchSection;
