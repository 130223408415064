import React, { useEffect, useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import PropTypes from "prop-types";
import "./index.css";
import { PurchaseStoresListLayout } from "../../layouts";
import { NavigatePage, MainWrapper } from "../../components";
import AppraisalsLayout from "../../layouts/appraisals";
import { BuyerRegisterForm } from "../../components/adminPortalComponents/buyerRegister/buyerRegisterForm";
import { MatchingConditionForm } from "../../components/adminPortalComponents/matchingCondition/matchingConditionForm";
import { useAppStore } from "../../stores/app.store";
import NoticeLayout from "../../layouts/notices";
import { useLocation } from "react-router-dom";
import RejectedApplications from "../../layouts/rejected_applications";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function AdminPortal() {
  const [value, setValue] = React.useState(0);
  const { state } = useLocation();

  useEffect(() => {
    if (state && state.selectedTabDashboard) {
      setValue(state.selectedTabDashboard);
    }
  }, [state]);

  const {
    formTabIndex,
    setFormTabIndex,
    setEditRegisterFormStatus,
    setMainTabIndex,
    mainTabIndex,
    setMatchingConditonTabIndex,
    matchingCondtionTabIndex,
    setIsTabPageAction,
  } = useAppStore();

  // Handle tab change
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setIsTabPageAction(false);

    // Update URL without reloading the page
    window.history.pushState({}, "", `?tab=${newValue}`);
  };

  useEffect(() => {
    // Read tab index from URL when component mounts
    const queryParams = new URLSearchParams(window.location.search);
    const tabIndex = parseInt(queryParams.get("tab") || "0", 10);
    setValue(tabIndex);

    const handlePopState = (event) => {
      const newTabIndex = parseInt(
        new URLSearchParams(window.location.search).get("tab") || "0",
        10
      );
      setValue(newTabIndex);
    };

    // Add event listener for popstate to handle browser back/forward buttons
    window.addEventListener("popstate", handlePopState);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  useEffect(() => {
    if (value !== 5) {
      setFormTabIndex(false);
    }
    if (formTabIndex) {
      handleChange("", 5);
      setIsTabPageAction(true);
    }

    if (value !== 6) {
      setMatchingConditonTabIndex(false);
    }
    if (matchingCondtionTabIndex) {
      handleChange("", 6);
      setIsTabPageAction(true);
    }

    if (value !== 0) {
      setMainTabIndex(false);
    }
    if (mainTabIndex) {
      handleChange("", 0);
    }
    console.log(mainTabIndex, "main app");
  }, [formTabIndex, matchingCondtionTabIndex, mainTabIndex, value]);

  return (
    <MainWrapper>
      <NavigatePage />
      <div className="tabs-wrapper">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="買取店一覧" {...a11yProps(0)} />
              <Tab label="査定依頼一覧" {...a11yProps(1)} />
              <Tab label="お知らせ" {...a11yProps(2)} />
              <Tab label="却下申請一覧" {...a11yProps(3)} />
              <Tab label="システム改善要望一覧" {...a11yProps(4)} />
              <Tab sx={{ display: "none" }} {...a11yProps(5)} />
              <Tab sx={{ display: "none" }} {...a11yProps(6)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            <PurchaseStoresListLayout />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <AppraisalsLayout />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <NoticeLayout />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <RejectedApplications />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            coming soon
          </CustomTabPanel>

          {value === 5 && (
            <CustomTabPanel value={value} index={5}>
              <BuyerRegisterForm />
            </CustomTabPanel>
          )}
          {value === 6 && (
            <CustomTabPanel value={value} index={6}>
              <MatchingConditionForm />
            </CustomTabPanel>
          )}
        </Box>
      </div>
    </MainWrapper>
  );
}

export default AdminPortal;
