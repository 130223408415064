import Button from "../../components/button";
import ToggleButton from "../../components/toggle-button";
import { useEffect, useRef, useState } from "react";
import Input from "../../components/input";
import TextArea from "../../components/textarea";
import option from "../../data/car.json";
import DropDown from "../../components/dropdown";
import Radio from "../../components/choise";
import choise from "../../data/choises.json";
import DateTime from "../../components/datetime";
import { useBackend } from "../../hooks/useBackend";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import axios from 'axios';


export default function Edit(props) {
  const [clicked, setClicked] = useState({ on: false, off: true });

  const [data, setData] = useState(props.data);
  const [edited, setEdited] = useState({
    id: props?.data?.appraisalid?.content,
  });
  const [clickedShop, setClickedShop] = useState(
    data?.shops?.shops.map((shop) => shop.is_rejected_by_shop) || []
  );
  const [maker, setm] = useState(data?.car?.car_maker); // 車種と紐づけが必要なため外出し
  const [carType, setCarType] = useState(data?.car?.car_type);
  const [carModelYear, setCarModelYear] = useState(data?.car?.car_model_year);
  const [carGrade, setCarGrade] = useState(data?.car?.grade);

  const [filteredCarTypes, setFilteredCarTypes] = useState([]);
  const [carModelYearOptions, setCarModelYearOptions] = useState([]);
  const [carGrades, setCarGrades] = useState([]);
  const [statusType, setStatusType] = useState(data?.type);

  const [firstAssessmentDate, setFirstAssessmentDate] = useState(
    data?.aDates?.content[0]?.content
  );
  const [secondAssessmentDate, setSecondAssessmentDate] = useState(
    data?.aDates?.content[1]?.content
  );
  const [thirdAssessmentDate, setThirdAssessmentDate] = useState(
    data?.aDates?.content[2]?.content
  );
  const [utmParam, setUtmParam] = useState(data?.saved_utm_param);
  const [newOption, setNewOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCarTypeTyped, setIsCarTypeTyped] = useState(false);
  const [isYearTyped, setIsYearTyped] = useState(false);

  const firstADateRef = useRef();
  const secondADateRef = useRef();
  const thirdADateRef = useRef();
  const api = useBackend();
  const dateTimeValueFromRef = (r) =>
    Array.from(r.current.children[0]?.children).filter(
      (e) => e.nodeName === "INPUT"
    )[0]?.value;
  const correctDateTime = (t) =>
    !(
      t === "" ||
      t.match(/Y/) ||
      t.match(/M/) ||
      t.match(/D/) ||
      t.match(/h/) ||
      t.match(/m/) ||
      t.match(/s/)
    ); 
    useEffect(() => {
      const fetchData = async () => {
        try {
          setIsLoading(true);
          // Generate an array of URLs for the JSON files
          const urls = Array.from({ length: 96 }, (_, i) => 
            `https://d3jbkxdk2o6mwn.cloudfront.net/vehicle-master-data/${i + 1}.json`
          );
      
          // Create an array of axios get requests
          const fetchRequests = urls.map(url =>
            axios.get(url, {
              headers: {
                "Accept-Encoding": "gzip,br"
              }
            }).catch(error => {
              console.error(`Error fetching ${url}: `, error);
              return { data: [] }; // Return an empty array on error
            })
          );
      
          // Wait for all axios requests to resolve
          const responses = await Promise.all(fetchRequests);
      
          // Extract data from all responses
          const dataArray = responses.map(response => response.data);
      
          // Merge all data into a single array
          const mergedData = dataArray.flat();
      
          // Set the merged data to your state
          setNewOption(mergedData);
          setIsLoading(false);
        } catch (error) {
          setIsLoading(true);
          console.error("Error fetching data: ", error);
        }
      };
      fetchData();
    }, []);
  
  const onComplete = (e) => {
    let query = edited;
    let firstADate = dateTimeValueFromRef(firstADateRef);
    if (correctDateTime(firstADate)) {
      query.assessment_date_first = dateTimeValueFromRef(firstADateRef);
      setFirstAssessmentDate(dateTimeValueFromRef(firstADateRef));
    }
    let secondADate = dateTimeValueFromRef(secondADateRef);
    if (correctDateTime(secondADate)) {
      query.assessment_date_second = dateTimeValueFromRef(secondADateRef);
      setSecondAssessmentDate(dateTimeValueFromRef(secondADateRef));
    }

    let thirdADate = dateTimeValueFromRef(thirdADateRef);
    if (correctDateTime(thirdADate)) {
      query.assessment_date_third = dateTimeValueFromRef(thirdADateRef);
      setThirdAssessmentDate(dateTimeValueFromRef(thirdADateRef));
    }
    if(statusType) {
      query.type = statusType;
    } else {
      query.type = "Phone";
    }
      
    if (query?.status === undefined && data?.status === undefined) {
      alert("ステータスが選択されていません。");
      return;
    }

    const regex = /^0[789]0\d{8}$/;
    if (
      !regex.test(query?.customer_phone) &&
      !regex.test(data?.customer?.phone?.content)
    ) {
      alert("ハイフンなしの正しい電話番号を入力して下さい。");
      return;
    }
    api.edit(query).then((e) => {
      if (props.onClose) props.onClose();
    });
  };
  const close = (e) => {
    if (!props.onClose) return;
    if (e.target.classList.toString().includes("mordal_background"))
      props.onClose();
  };
  const set = (d) => {
    setEdited((pre) => ({ ...pre, ...d }));
  };

  const handleTypeValue = (value) => {
    if (value === '架電'){
      value = 'Phone';
    }
    setStatusType(value);
  };

  const setMaker = (d) => {
    set(d);
    set({ car_type: "(指定なし)" });
    setm(d.car_maker);
    setFilteredCarTypes([]);
    setCarType("");
  };

  const setCarT = (d) => {
    set(d);
    set({ car_model_year: "(指定なし)" });
    setCarType(d.car_type);
    setCarModelYearOptions([]);
    setCarModelYear("");
  };

  const setCarYr = (d) => {
    set(d);
    set({ grade: "(指定なし)" });
    setCarModelYear(d.car_model_year);
    setCarGrades([]);
    setCarGrade("");
  };

  const setCarG = (d) => {
    set(d);
    setCarGrade(d.grade);
  };

  useEffect(() => {
    // Filter car types when the maker state changes
    if (maker) {
      const selectedMaker = newOption.find((item) => item.brand === maker);
      if (selectedMaker) {
        const carTypes = selectedMaker.maqh.map((type) => ({
          value: type.name,
          label: type.name,
        }));
        const carTypesWithDefault = [
          { value: "(指定なし)", label: "(指定なし)" }, // Adding the default option
          ...carTypes,
        ];
        // setCarType(""); // Reset selected car type
        setFilteredCarTypes(carTypesWithDefault);
      }
    } else {
      // Reset car types if no maker is selected
      setFilteredCarTypes([]);
    }
  }, [maker, newOption]);

  useEffect(() => {
    // Filter 平成14(2002) values based on selected car type
    if (carType) {
      const selectedCar = newOption
        .find((item) => item.brand === maker)
        ?.maqh.find((type) => type.name === carType);
      if (selectedCar) {
        const modelYears =
          selectedCar.mapx?.map((modelYear) => ({
            value: modelYear.name,
            label: modelYear.name,
          })) || [];
        const modelYearsWithDefault = [
          { value: "(指定なし)", label: "(指定なし)" }, // Adding the default option
          ...modelYears,
        ];
        setCarModelYearOptions(modelYearsWithDefault);
      }
    } else {
      // Reset options if no car type is selected
      setCarModelYearOptions([]);
    }
  }, [carType, maker, newOption]);

  useEffect(() => {
    // Filter grades based on selected car type
    if (carModelYear) {
      const selectedCar = newOption
        .find((item) => item.brand === maker)
        ?.maqh.find((type) => type.name === carType);
      if (selectedCar) {
        const selectedModelYear = selectedCar.mapx.find(
          (modelYear) => modelYear.name === carModelYear
        );
        if (selectedModelYear) {
          const grades = selectedModelYear.makp.map((grade) => ({
            value: grade.name,
            label: grade.name,
          }));
          const gradesWithDefault = [
            { value: "不明", label: "不明" }, // Adding the default option
            ...grades,
          ];
          setCarGrades(gradesWithDefault);
        }
      }
    } else {
      // Reset grades if no car type is selected
      setCarGrades([]);
    }
  }, [carModelYear, carType, maker, newOption]);

  const handleClearFields = (fields) => {
    const clearedData = {};
    setIsYearTyped(false);
    fields.forEach((field) => (clearedData[field] = ""));
    setEdited((prev) => ({ ...prev, ...clearedData }));
  };

  const filterInsecptions = (data) => {
    let inspectRemain = data;

    if (
      data === "1ヶ月以内" ||
      data === "1ヵ月以内" ||
      data === "2ヶ月以内" ||
      data === "2ヵ月以内"
    ) {
      inspectRemain = "3ヵ月以内";
    } else if (data === "3ヶ月以内") {
      inspectRemain = "3ヵ月以内";
    } else if (data === "6ヶ月以内") {
      inspectRemain = "6ヵ月以内";
    } else if (data === "未定") {
      inspectRemain = "不明";
    }

    return inspectRemain;
  };

  const filterAccident = (data) => {
    let accident = data;
    if (accident === "あり(修復済み)") {
      accident = "あり(修復済)";
    }
    return accident;
  };

  const filterDesireDate = (data) => {
    let desireDate = data;

    if (data === "1ヶ月以内" || data === "1ヵ月以内") {
      desireDate = "1ヵ月以内";
    } else if (data === "2ヶ月以内" || data === "2ヵ月以内") {
      desireDate = "2ヵ月以内";
    } else if (data === "未定") {
      desireDate = "未定";
    }

    return desireDate;
  };

  const filterRunnable = (data) => {
    let desireDate = data;
    if (data === "走行可能") {
      desireDate = "走行可";
    }
    return desireDate;
  };

  const filterDistance = (data) => {
    let distanceT = data;
    if (data === "不明") {
      distanceT = "（指定なし）";
    } else if (data === "210,000km～") {
      distanceT = "～210,000km";
    }
    return distanceT;
  };

  const rejectedToggle = (index, shop) => {
    const body = {
      appraisalId: data?.appraisalid?.content,
      shopId: shop?.shopid?.content,
      isRejectedByShop: !shop?.is_rejected_by_shop,
    };

    api.rejected_by_shop(body).then((e) => {
      //console.log(e);
    });
  };

  return (
    <div className={`mordal_background`} onClick={close}>
      <div className="mordal_edit">
        <h2>ステータス・備考</h2>
        <div className="flex-status-note">
          <div className="flex_status">
            <Radio
              label="ステータス"
              id="status"
              option={choise.statusAppraisal}
              selected={data?.status}
              narrow
              onChange={set}
              data={data}
            />
            {/* add types */}
            <Box
              marginTop={3}
            >
              <Grid container direction="column" alignItems="flex-start">
                <Grid item>
                  <h4 style={{ margin: 0, fontWeight: 'bold' }}>窓口</h4>
                </Grid>
                <Grid item container justifyContent="left" marginTop={1}>
                  <Stack
                    direction={{ sm: "column", md: "row" }}
                    spacing={1}
                    sx={{}}
                  >
                    {["SEO", "Paid", "Paid2", "Paid3","Paid4", "架電"].map((label) => (
                      <Button
                        key={label}
                        small2
                        label={label}
                        onClick={() => handleTypeValue(label)}
                        color={(statusType === label || (statusType === "Phone" && label === "架電")) ? "mainNew on" : "main off"}
                        has_state
                      />
                    ))}
                  </Stack>
                </Grid>
              </Grid>
            </Box>

          </div>
          <div className="flex-note-appendix">
            <div className="flex_note">
              <TextArea
                label="備考"
                id="note"
                value={data?.supplement?.note}
                onChange={set}
              />
            </div>
            <div className="flex_note">
              <TextArea
                label="連絡事項"
                id="message_for_matching_shop"
                value={data?.supplement?.message_for_matching_shop}
                onChange={set}
              />
            </div>
            <div className="flex_note_v2">
              <Input
                label="パラメータ"
                id="param"
                selected={data?.supplement?.param}
                onChange={set}
              />
            </div>
          </div>
        </div>
        {data?.shops && (
          <>
            <h2>選択済みの買取店</h2>
            <div className="flex">
              <div className="column">
                {data?.shops?.shops.map((shop, index) => (
                  <Grid container spacing={2} key={index} alignItems={"center"}>
                    <Grid item xs={3}>
                      {shop.name}
                    </Grid>
                    <Grid item xs={2}>
                      <ToggleButton
                        long
                        label={clickedShop[index] ? "元に戻す" : "却下"}
                        color={"main"}
                        custom={"h-30"}
                        has_state={clickedShop[index]}
                        onClick={(e) => {
                          const newClicked = [...clickedShop]; // Create a copy of clicked state
                          newClicked[index] = !newClicked[index]; // Toggle state at the index
                          setClickedShop(newClicked); // Update clicked state with the new array
                          rejectedToggle(index, shop);
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </div>
            </div>
          </>
        )}

        <h2>基礎情報</h2>

        <div className="flex">
          <div className="column">
            <div className="space_around">
              <DropDown
                label="メーカー"
                options={[
                  { value: "(指定なし)", label: "(指定なし)" },
                  ...newOption.map((item) => ({
                    value: item.brand,
                    label: item.brand,
                  })),
                ]}
                name="car_maker"
                id="car_maker"
                selected={maker}
                onChange={setMaker}
                disabled={isLoading}
              />
            </div>
            <div className="space_around">
              <DropDown
                label="車種"
                options={filteredCarTypes}
                name="car_type"
                id="car_type"
                selected={carType}
                onChange={setCarT}
                onClear={handleClearFields}
                onTypeChange={setIsCarTypeTyped}
              />
            </div>
            <div className="space_around">
              <DropDown
                label="年式"
                options={carModelYearOptions}
                name="car_model_year"
                id="car_model_year"
                selected={carModelYear}
                onChange={setCarYr}
                onClear={handleClearFields}
                parentCarTypeIsTyped={isCarTypeTyped}
                onTypeChange={setIsYearTyped}
              />
            </div>
            <div className="space_around">
              <DropDown
                label="走行距離"
                options={option.distance2}
                name="car_traveled_distance"
                id="car_traveled_distance"
                selected={filterDistance(data?.car?.car_traveled_distance)}
                onChange={set}
                disabled={isLoading}
              />
            </div>
            <div className="space_around">
              <DropDown
                label="グレード"
                options={carGrades}
                name="grade"
                id="grade"
                selected={carGrade}
                onChange={setCarG}
                // shouldHideOptions={isYearTyped}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Input
                label="電話番号"
                id="customer_phone"
                selected={data?.customer?.phone?.content}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Input
                label="名前"
                id="customer_name"
                selected={data?.customer?.name}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Input
                label="メール"
                id="customer_email"
                selected={data?.customer?.email?.content}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Input
                label="郵便番号"
                id="customer_post_number"
                selected={data?.customer?.post_number}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <DropDown
                label="都道府県"
                options={choise.prefecture}
                name="customer_prefecture"
                id="customer_prefecture"
                selected={data?.customer?.prefecture}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Input
                label="市区町村"
                id="customer_municipalities"
                selected={data?.customer?.municipalities}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Input
                label="番地"
                id="customer_address"
                selected={data?.customer?.address}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Input
                label="ビル名等"
                id="customer_address_detail"
                selected={data?.customer?.address_detail}
                onChange={set}
              />
            </div>
          </div>
        </div>

        <h2>車両</h2>
        <div className="flex">
          <div className="column">
            <div className="space_around">
              <Radio
                label="車検日"
                id="inspect_remain"
                option={choise.inspect_remain}
                selected={filterInsecptions(data?.car?.inspect_remain)}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="車両状態"
                id="car_state"
                option={choise.car_state}
                selected={data?.car?.car_state}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="自走可否"
                id="runnable"
                option={choise.runnable}
                selected={filterRunnable(data?.car?.runnable)}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="駆動"
                id="wheel_drive"
                option={choise.wheel_drive}
                selected={data?.car?.wheel_drive}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Radio
                label="燃料"
                id="fuel"
                option={choise.fuel}
                selected={data?.car?.fuel}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="シフト"
                id="shift"
                option={choise.shift}
                selected={data?.car?.shift}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="事故歴・修復歴"
                id="accident"
                option={choise.accident}
                selected={filterAccident(data?.car?.accident)}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <DropDown
                label="排気量"
                options={option.displacement}
                name="displacement"
                id="displacement"
                selected={data?.car?.displacement}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <DropDown
                label="ボディカラー"
                options={choise.body_color}
                name="body_color"
                id="body_color"
                selected={data?.car?.body_color}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Input
                label="ローン残債"
                id="loan"
                selected={data?.car?.loan}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <DropDown
                label="売却希望時期"
                options={choise.desire_date}
                name="desire_date"
                id="desire_date"
                selected={filterDesireDate(data?.car?.desire_date)}
                onChange={set}
              />
            </div>
          </div>
        </div>

        <h2>外装の状態</h2>
        <div className="flex">
          <div className="column">
            <div className="space_around">
              <Radio
                label="全体状況"
                id="exterior"
                option={choise.exterior}
                selected={data?.car?.exterior}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Radio
                label="傷"
                id="scratch"
                option={choise.scratch}
                selected={data?.car?.scratch}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="へこみ"
                id="dent"
                option={choise.dent}
                selected={data?.car?.dent}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Radio
                label="塗装剥げ"
                id="peel"
                option={choise.peel}
                selected={data?.car?.peel}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="サビ"
                id="rust"
                option={choise.rust}
                selected={data?.car?.rust}
                onChange={set}
              />
            </div>
          </div>
        </div>

        <h2>内装の状態</h2>
        <div className="flex">
          <div className="column">
            <div className="space_around">
              <Radio
                label="全体状況"
                id="interior"
                option={choise.interior}
                selected={data?.car?.interior}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Radio
                label="汚れ/シミ"
                id="dirt"
                option={choise.dirt}
                selected={data?.car?.dirt}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="シート破れ"
                id="tear"
                option={choise.tear}
                selected={data?.car?.tear}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Radio
                label="エアコン"
                id="air_conditioning"
                option={choise.air_conditioning}
                selected={data?.car?.air_conditioning}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="禁煙/喫煙"
                id="smoke"
                option={choise.smoke}
                selected={data?.car?.smoke}
                onChange={set}
              />
            </div>
          </div>
        </div>

        <h2>装備品</h2>
        <div className="flex">
          <div className="column">
            <div className="space_around">
              <Radio
                label="ナビ"
                id="navigation"
                option={choise.navigation}
                selected={data?.car?.navigation}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="電動スライド"
                id="auto_slide"
                option={choise.auto_slide}
                selected={data?.car?.auto_slide}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Radio
                label="革シート"
                id="leather_sheet"
                option={choise.leather_sheet}
                selected={data?.car?.leather_sheet}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="ハンドル"
                id="handle_type"
                option={choise.handle_type}
                selected={data?.car?.handle_type}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="バックモニター"
                id="back_monitor"
                option={choise.back_monitor}
                selected={data?.car?.back_monitor}
                onChange={set}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <Radio
                label="サンルーフ"
                id="sunroof"
                option={choise.sunroof}
                selected={data?.car?.sunroof}
                onChange={set}
              />
            </div>
            <div className="space_around">
              <Radio
                label="タイヤホイール"
                id="wheel"
                option={choise.wheel}
                selected={data?.car?.wheel}
                onChange={set}
              />
            </div>
          </div>
        </div>

        <h2>査定希望日</h2>
        <div className="flex">
          <div className="column">
            <div className="space_around">
              <DateTime
                _ref={firstADateRef}
                label="第一査定希望日"
                name="maker"
                id="maker"
                selected={firstAssessmentDate}
                onChange={(date) => setFirstAssessmentDate(date)}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <DateTime
                _ref={secondADateRef}
                label="第二査定希望日"
                name="maker"
                id="maker"
                selected={secondAssessmentDate}
                onChange={(date) => setSecondAssessmentDate(date)}
              />
            </div>
          </div>
          <div className="column left_border">
            <div className="space_around">
              <DateTime
                _ref={thirdADateRef}
                label="第三査定希望日"
                name="maker"
                id="maker"
                selected={thirdAssessmentDate}
                onChange={(date) => setThirdAssessmentDate(date)}
              />
            </div>
          </div>
        </div>

        <h3>アフィリエイト媒体: {utmParam || "--"}</h3>
        <div className="spacer"></div>
        <div className="fix_button">
          <Button color={"main"} on small label="確定" onClick={onComplete} />
        </div>
      </div>
    </div>
  );
}
