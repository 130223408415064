import { useMemo, useRef, useState } from "react";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { Link, useParams } from "react-router-dom";
import { ModalComponent } from "../../components";
import ReconfirmModal from "./reconfirm-modal";

const BillingListTable = (props) => {
  const currentMonth = new Date().getMonth() + 1;
  const yearRef = useRef("");
  const monthRef = useRef("");
  const shopIdRef = useRef("");
  const [isOpen, setIsOpen] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const { companyId } = useParams();

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const onRequest = (data) => {
    props.onRequest(data);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "japaneeseMonth",
        header: "",
        size: 80,
        enableHiding: false,
        Cell: ({ row }) => {
          const isMainRow = row?.original?.shopTypeEnum;
          return isMainRow === "HEAD_BRANCH" ? row.original.japaneeseMonth : row.original.name;
        },
      },
      {
        accessorKey: "classification",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>区分</p>
        ),
        Cell: () => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>非独占</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>独占</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>合計</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "visitors",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            当月送客数
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "ASSESSED",
                      type: "NON_EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.countAssessedNonEx}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "ASSESSED",
                      type: "EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.countAssessedEx}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "ASSESSED",
                      type: "BOTH"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.totalNumberOfGuest}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "rejections",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            前月却下数
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "LREJ",
                      type: "NON_EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.rejectionInLastMonthNonEx}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "LREJ",
                      type: "EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.rejectionInLastMonthEx}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "LREJ",
                      type: "BOTH"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.totalNumberRejectionInLastMonth}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "cancellations",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            前月キャンセル数
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "NON_EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.cancellationInLastMonthNonEx}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.cancellationInLastMonthEx}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.id}`,
                      previousPageSubLevel: `/console/dashboard`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "BOTH"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.totalNumberCancellationInLastMonth}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "numberOfNegativeBillingsPreviousMonth",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            前月マイナス請求数
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.name}`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "NON_EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.lastMonthNegNonEx ?? 0}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.name}`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.lastMonthNegEx ?? 0}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.name}`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "BOTH"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.lastMonthNegTotal ?? 0}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "numberOfNegativeBillingsPreviousTwoMonths",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            前々月マイナス請求数
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.name}`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "NON_EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.lastTwoMonthNegNonEx ?? 0}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.name}`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "EX"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.lastTwoMonthNegEx ?? 0}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Link
                    to={`/console/assessment-request-list/${row?.original?.id}/${row?.original?.shopTypeEnum}/${companyId}?${new URLSearchParams({
                      previousPage: `/console/billing/${row?.original?.name}`,
                      year: row?.original?.year,
                      month: row?.original?.month,
                      attribute: "CANCEL",
                      type: "BOTH"
                    }).toString()}`}
                  >
                    <span className="table-row-clickable-item">
                      {row?.original?.lastTwoMonthNegTotal ?? 0}
                    </span>
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "perCustomer",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            送客単価
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>{row?.original?.introduction}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{row?.original?.referral}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>_</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "amountBilledOrClaimed",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            請求金額
          </p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>{row?.original?.nonExclusiveAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{row?.original?.exclusiveAmount}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{row?.original?.amount}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "billingStatus",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            請求 ステータス
          </p>
        ),
        Cell: ({ row }) => (
          <span className="table-group-by-cell">
            {row?.original?.billingStatus
              ? row?.original?.billingStatus === "BILLED"
                ? "請求済み"
                : "請求前"
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "depositStatus",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>
            入金ステータス
          </p>
        ),
        Cell: ({ row }) => (
          <span className="table-group-by-cell">
            {row?.original?.depositStatus
              ? row?.original?.depositStatus !== "BEFORE_DEPOSIT"
                ? "入金済み"
                : "入金前"
              : "_"}
          </span>
        ),
      },
      {
        accessorKey: "numberOfRefunds",
        enableHiding: false,
        maxSize: 100,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}>返金数</p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <Table className="mui-table-row-cell">
            <TableBody>
              <TableRow>
                <TableCell>{row?.original?.refundAmountNonExCount ?? 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{row?.original?.refundAmountExCount ?? 0}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{row?.original?.totalRefundAmountCount ?? 0}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        ),
      },
      {
        accessorKey: "actionButtons",
        enableHiding: false,
        maxSize: 140,
        Header: () => (
          <p style={{ whiteSpace: "initial", margin: 0, padding: 0 }}></p>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <div className="ctn-wrapper table-group-by-cell">
            <Grid container>
              {row?.original?.billingStatus === "BILLED"  ? (
                <>
                  <Grid marginTop={2}>
                    <Button
                      variant="contained"
                      disabled={row?.original?.month === currentMonth}
                      onClick={() => {
                        yearRef.current = row?.original?.year;
                        monthRef.current = row?.original?.month;
                        shopIdRef.current = row?.original?.id;
                        handleOpen();
                        setPaymentType(row?.original?.paymentMethod);
                      }}
                    > {row?.original?.month === currentMonth ? "請求する" : "再請求する"}
                      
                    </Button>
                  </Grid>
                </>
              ) : (
                <Grid marginTop={2}>
                  <Button
                    variant="contained"
                    disabled={row?.original?.month === currentMonth}
                    onClick={() => {
                      onRequest(row?.original);
                    }}
                  >
                    請求する
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: props.data && props.data.length ? props.data : [],
    getSubRows: (row) => row.branches,
    enableExpanding: true,
    enableRowSelection: false,
    enableColumnOrdering: false,
    enableGlobalFilter: false,
    enableTopToolbar: false,
    enableSorting: false,
    enableFilters: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    localization: {
      rowsPerPage: "ページあたりの行数",
      of: "の",
    },
    initialState: {
      columnPinning: { right: ['actionButtons'] },
    },
  });

  return (
    <div className="material-react-table-body table-group-by">
      <MaterialReactTable table={table} />
      <ModalComponent width={400} isOpen={isOpen} handleClose={handleClose}>
        <ReconfirmModal
          handleClose={handleClose}
          year={yearRef.current}
          month={monthRef.current}
          shopId={shopIdRef.current}
          transferType={paymentType == "BILLING" ? "振込期日を選択" : "引落予定日を選択"}
        />
      </ModalComponent>
    </div>
  );
};

export default BillingListTable;
