import React, { useState, useEffect, useMemo } from "react";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Select } from "./select";

export const MultipleOptionList = (props) => {
  const [errors, setErrors] = useState([]);

  const selectedCombinations = useMemo(() => {
    return props?.inputs?.map(
      (input) => `${input?.prefectures}-${input?.manicipalities}`
    );
  }, [props?.inputs]);

  useEffect(() => {

    props?.inputs?.forEach((input, index) => {
      if (input?.prefectures && props?.onPrefectureChange) {
        props?.onPrefectureChange(input?.prefectures, index);
      }
    });
  }, []);

  const handleAddInput = () => {
    props.setInputs([
      ...props?.inputs,
      { prefectures: "", manicipalities: "" },
    ]);
    setErrors([...errors, { pref: false, manci: false }]);
  };

  const handleRemoveInput = (index) => {
    const newInputs = props?.inputs?.filter((_, i) => i !== index);
    props?.setInputs(newInputs);
  };

  const handleInputChange = (index, type, value) => {
    const newInputs = props?.inputs?.map((input, i) => {
      if (i === index) {
        return { ...input, [type]: value };
      }
      return input;
    });
    props?.setInputs(newInputs);
  };

  const handlePrefectureChange = (index, value) => {
    const newInputs = props?.inputs?.map((input, i) => {
      if (i === index) {
        return { ...input, prefectures: value, manicipalities: "" };
      }
      return input;
    });

    props?.setInputs(newInputs);

    if (props?.onPrefectureChange) {
      props.onPrefectureChange(value, index);
    }
  };

  const handleMunicipalityChange = (index, value) => {
    const prefecture = props?.inputs[index].prefectures;
    const newCombination = `${prefecture}-${value}`;

    if (!selectedCombinations.includes(newCombination)) {
      handleInputChange(index, "manicipalities", value);
    }
  };

  return (
    <div className="w-full h-full">
      {props?.inputs?.map((input, index) => (
        <div key={index} className="flex items-center gap-4">
          <div className="w-full relative">
            <Select
              options={props?.firstOptions}
              fullWidth
              className="h-10 w-full bg-[#fff9c5] my-2"
              onChange={(value) => handlePrefectureChange(index, value)}
              value={input?.prefectures}
              disable={props.disable}
            />
            {!input.prefectures && errors[index]?.pref && (
              <h1 className="text-red-500">このフィールドは必須です</h1>
            )}
          </div>

          <div className="w-full relative">
            <Select
              options={props?.secondOptions[input.prefectures] || []}
              fullWidth
              className="h-10 w-full bg-[#fff9c5] my-2"
              onChange={(value) => handleMunicipalityChange(index, value)}
              value={input?.manicipalities}
              disable={props?.disable || !input.prefectures}
            />
            {!input.manicipalities && errors[index]?.manci && (
              <h1 className="text-red-500">このフィールドは必須です</h1>
            )}
          </div>

          {index > 0 && (
            <Button onClick={() => handleRemoveInput(index)}>
              <MinusOutlined />
            </Button>
          )}
          <Button
            onClick={() => {
              const newErrors = props?.inputs?.map((input, i) => ({
                pref: !input?.prefectures,
                manci: !input?.manicipalities,
              }));
              setErrors(newErrors);
              if (newErrors?.every((error) => !error?.pref && !error?.manci)) {
                handleAddInput();
              }
            }}
            disabled={
              (!input?.prefectures && !input?.manicipalities) ||
              input.prefectures === "全国"
            }
          >
            <PlusOutlined />
          </Button>
        </div>
      ))}
    </div>
  );
};