import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const rejectApi = createApi({
    reducerPath: "rejectApi",
    baseQuery: fetchBaseQuery({
      baseUrl: `${process.env.REACT_APP_ENDPOINT_V2}`,
      prepareHeaders: (headers) => {
        const token = localStorage.getItem("token");
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      },
    }),
    tagTypes: ["RejectAppraisals"],
    endpoints: (builder) => ({
        getRejectAppraisals: builder.query({
            query: (assessedStatus) => ({
              url: `/reject/appraisal/buyer-rejected`,
              method: "GET",
              params: assessedStatus ? { assessedStatus } : undefined,
            }),
            providesTags: [{ type: "RejectAppraisals" }],
          }),
  
      updateAppraisalStatus: builder.mutation({
        query: ({ appraisalId, shopId, status }) => ({
          url: `/reject/appraisal/status/${appraisalId}/${shopId}`,
          method: "PUT",
          params: { status }, 
        }),
        invalidatesTags: [{ type: "RejectAppraisals" }], 
      }),

      getRejectAppraisalsById: builder.query({
        query: (appraisalId) => ({
          url: `/appraisals/appraisal-request/${appraisalId}`,
          method: "GET"
        }),
        providesTags: [{ type: "RejectAppraisalsById" }],
      }),

    }),
  });
  
  export const {useGetRejectAppraisalsQuery, useLazyGetRejectAppraisalsQuery, useUpdateAppraisalStatusMutation,useGetRejectAppraisalsByIdQuery } = rejectApi;